// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** MiscPageWrapper */
const MiscPageWrapper = ({ children, ...props }) => (
  <StandardPageWrapper {...props}>
    <Row justify="space-between" className="misc-page">
      <Col xs={0} sm={0} md={18} lg={18} xl={18} xxl={18}>
        {children}
      </Col>
      <Col xs={0} sm={0} md={6} lg={6} xl={6} xxl={6}>
        <div className="bubble n1">
          <ul>
            <li>
              <Link to="/about">About Auroville Archives</Link>
            </li>
            <li>
              <Link to="/about#origins">Origins</Link>
            </li>
            <li>
              <Link to="/about#ongoing-efforts">Ongoing Efforts</Link>
            </li>
            <li>
              <Link to="/about#team">Team</Link>
            </li>
          </ul>
        </div>
        <div className="bubble n2">
          <ul>
            <li>
              <Link to="/participate">Participate</Link>
            </li>
            <li>
              <Link to="/participate#volunteer">Volunteer</Link>
            </li>
            <li>
              <Link to="/participate#apprentice">Apprentice</Link>
            </li>
            <li>
              <Link to="/participate#research">Research</Link>
            </li>
          </ul>
        </div>
        <div className="bubble n3">
          <ul>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/participate#newsletter">Newsletter</Link>
            </li>
            <li>
              <Link to="/participate#submit-materials">Submit Materials</Link>
            </li>
          </ul>
        </div>
      </Col>
    </Row>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default MiscPageWrapper
